import React, { useContext } from "react";
import { StanContext } from "../context";
import { Link } from "react-router-dom";
import ulazA from "../../assets/buildingPhotos/zgrada4.jpeg";
import ulazB from "../../assets/buildingPhotos/zgrada5.jpeg";
const UlaziButtons = () => {
  const { setUlaz } = useContext(StanContext);
  return (
    <div className="flex justify-center py-10">
      <div
        className="w-2/5 lg:w-1/3 rounded border-2 border-teal-900 skew-x-12 border-solid overflow-hidden transition-all hover:bg-teal-900 hover:text-white "
        onClick={() => {
          setUlaz("A");
        }}>
        <Link to="/enters/select-apartment">
          <img
            src={ulazA}
            alt="sliak1"
            className="w-screen scale-x-125 h-3/4 skew-x-[-12deg]"
          />
          <h1 className="h-1/4 text-3xl flex justify-center items-center skew-x-[-12deg]">
            ULAZ A
          </h1>
        </Link>
      </div>
      <div
        className="w-2/5 lg:w-1/3 rounded border-2 border-teal-900 skew-x-12 border-solid overflow-hidden transition-all hover:bg-teal-900 hover:text-white"
        onClick={() => {
          setUlaz("B");
        }}>
        <Link to="/enters/select-apartment">
          <img
            src={ulazB}
            alt="slika 2"
            className="w-screen scale-x-125 h-3/4 skew-x-[-12deg]"
          />
          <h1 className="h-1/4 text-3xl flex justify-center items-center skew-x-[-12deg]">
            ULAZ B
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default UlaziButtons;
