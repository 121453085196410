import React, { useContext } from "react";
import Layout from "../Layout/layout";
import Benefits from "../Sections/Benefits";
import StanTypeSelection from "../Sections/StanTypeSelection";
import { StanContext } from "../context";
import UlaziButtons from "../Sections/UlaziButtons";

const StanoviPage = () => {
  const { setUlaz } = useContext(StanContext);
  return (
    <Layout>
      <div className="relative z-10 bkg">
        <div className="py-16 flex  flex-col justify-center items-center gap-10">
          <div className="lg:w-[80%] mt-10 text-center">
            <h1 className="text-6xl mb-8 text-teal-900">Stanovi u ponudi</h1>

            <p className="text-center mb-2 text-xl px-8">
              Izgradnja objekta u Novom Pazaru, ulica Save Kovačevića bb, koja
              je jedna od urbanih lokacija u zoni grada koja je u razvitku kako
              za život, tako i za poslovanje.
            </p>
            <p className="text-center mb-2 text-xl px-8">
              Agenti u sektoru za marketing i prodaju nekretnina pružiće vam sve
              potrebne informacije, oni vrše direktnu prodaju, bez posrednika, a
              obučeni su tako da vam na najbolji način približe prostor koji
              prodajemo.
            </p>
          </div>
          <UlaziButtons />
        </div>
        <div className="flex flex-col items-center bg-teal-900">
          <div className="md:w-[80%] pt-20 text-teal-600 flex justify-center items-center text-center gap-6">
            <span className="h-0 w-[30%] hidden lg:block border border-solid border-teal-600"></span>
            <h2 className="w-max font-extralight text-2xl">
              Odaberite željenu strukturu stana
            </h2>
            <span className="h-0 w-[30%] hidden lg:block border border-solid border-teal-600"></span>
          </div>
          <div
            onClick={() => {
              setUlaz(false);
            }}
            className="flex justify-center items-center">
            <StanTypeSelection />
          </div>
        </div>
        <Benefits />
      </div>
    </Layout>
  );
};

export default StanoviPage;
