import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StanContext } from "../context";
import dvosobni from "../../assets/LAMELA B RENDERI/B 8.jpg";
import jednoiposobni from "../../assets/RENDERI LAMELA A/LAMELA A2.jpg";
import dvoiposobni from "../../assets/LAMELA B RENDERI/B 1.jpg";
import trosobni from "../../assets/RENDERI LAMELA A/LAMELA A11.jpg";
import cetvorosobni from "../../assets/RENDERI LAMELA A/LAMELA A4.jpg";
import petosobni from "../../assets/RENDERI LAMELA A/LAMELA A3.jpg";
const StanTypeSelection = () => {
  const { setStanType } = useContext(StanContext);
  const stanovi = [
    {
      id: 1,
      name: "JEDNOIPOSOBNI stanovi",
      imgUrl: jednoiposobni,
    },
    {
      id: 2,
      name: "DVOSOBNI stanovi",
      imgUrl: dvosobni,
    },
    {
      id: 3,
      name: "DVOIPOSOBNI stanovi",
      imgUrl: dvoiposobni,
    },
    {
      id: 4,
      name: "TROSOBNI stanovi",
      imgUrl: trosobni,
    },
    {
      id: 5,
      name: "ČETVOROSOBNI stanovi",
      imgUrl: cetvorosobni,
    },
    {
      id: 6,
      name: "PETOSOBNI stanovi",
      imgUrl: petosobni,
    },
  ];
  return (
    <div className="w-[80%] py-8 grid gap-8 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 ">
      {stanovi.map((stan) => {
        return (
          <div
            key={stan.id}
            className="border-2 border-solid border-teal-600"
            onClick={() => {
              setStanType(stan.name);
            }}>
            <Link to="/apartments-listing">
              <img src={stan.imgUrl} alt="stanovi" className="aspect-video" />
              <h2 className="text-center text-white text-lg py-2 font-light">
                {stan.name}
              </h2>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default StanTypeSelection;
