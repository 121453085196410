import React, { useState, useEffect } from "react";
import photo from "../../assets/logoTest.png";
import { Drawer, IconButton } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const HeaderMobile = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [openRight, setOpenRight] = useState(false);
  const navigate = useNavigate();

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <nav
      className=" w-full flex justify-between items-center xl:px-10 px-4 py-1 bg-teal-900 fixed top-0 z-30"
      id="nav">
      <img
        src={photo}
        alt="slika"
        className="h-20 m-2"
        onClick={() => {
          navigate("/");
        }}
      />
      <div
        className="space-y-2 bg-teal-600/40 p-4 rounded transition-all hover:bg-teal-200/50"
        onClick={openDrawerRight}>
        <span className="block w-8 h-0.5 bg-teal-400"></span>
        <span className="block w-8 h-0.5 bg-teal-400"></span>
        <span className="block w-5 h-0.5 bg-teal-400"></span>
      </div>
      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-4 bg-teal-900 text-white">
        <div className="h-4/5 mb-6 flex flex-col items-end z-50">
          <IconButton variant="text" color="teal" onClick={closeDrawerRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-12 w-8">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
          <ul className="h-4/5 list-none flex flex-col items-end justify-evenly mt-8 text-xl">
            <Link to="/">
              <li className="cursor-pointer hover:text-teal-600 transition-all">
                POČETNA
              </li>
            </Link>
            <Link to="/about">
              <li className="cursor-pointer hover:text-teal-600 transition-all">
                O NAMA
              </li>
            </Link>
            <Link to="/apartments">
              <li className="cursor-pointer hover:text-teal-600 transition-all">
                STANOVI U PONUDI
              </li>
            </Link>
            {/* <Link to="/enters">
              <li className="cursor-pointer hover:text-teal-600 transition-all">
                ULAZI
              </li>
            </Link> */}
            <Link to="/contact">
              <li className="cursor-pointer hover:text-teal-600 transition-all">
                KONTAKT
              </li>
            </Link>
          </ul>
          <img
            src={photo}
            alt="logo"
            className="bg-teal-900 absolute bottom-0 right-0 h-40 translate-x-[-25%] translate-y-[-25%]"
          />
        </div>
      </Drawer>
    </nav>
  );
};

export default HeaderMobile;
