import React, { useContext } from "react";
import { StanContext } from "../context";
import Layout from "../Layout/layout";

const StanPage = () => {
  const { fullStan } = useContext(StanContext);
  return (
    <Layout>
      <div className="relative z-10 bkg">
        <div className="w-full py-10 mt-24 md:mt-0  bg-teal-600 flex justify-center items-center gap-6">
          <h1 className="text-3xl md:text-4xl text-white text-center">
            {fullStan.name}
          </h1>
        </div>
        <div className="p-8 grid lg:grid-cols-2 grid-cols-1 justify-items-center gap-20 ">
          <div className="text-center lg:w-[80%] w-full ">
            <h1 className="text-2xl w-full p-4 text-white bg-teal-600 mb-4">
              3D PRIKAZ STANA
            </h1>

            <img src={fullStan.triDPrikaz} alt="stan" className="w-full" />
          </div>
          <div className="text-center lg:w-[80%] w-full">
            <h1 className="text-2xl w-full p-4 text-white bg-teal-600 mb-4">
              ORIJENTACIJA STANA- ULAZ A
            </h1>

            <img src={fullStan.orijentacijaStana} alt="lamela" />
          </div>
          <div className="text-center lg:w-[80%] w-full">
            <h1 className="text-2xl w-full p-4 text-white bg-teal-600 mb-4">
              RASOPRED PROSTORIJA
            </h1>
            <img src={fullStan.rasporedProstorija} alt="rasporedProstorija" />
          </div>

          <div className="w-full flex flex-col items-center justify-evenly gap-10">
            <div className="text-center w-full">
              <h1 className="text-2xl w-full p-4 text-white bg-teal-600 mb-4">
                KVADRATURA PROSTORIJA
              </h1>
              <table className="border border-gray-600 w-full break-all">
                <tbody>
                  <tr className="border border-gray-600">
                    <td className="border border-gray-600">BROJ</td>
                    <td className="border border-gray-600">PROSTORIJE</td>
                    <td className="border border-gray-600">Pm²</td>
                    <td className="border border-gray-600">POD</td>
                  </tr>
                  {fullStan.kvadraturaProstorija.map((el, i) => {
                    return (
                      <tr key={i} className="border border-gray-600">
                        <td className="border border-gray-600">
                          {el.raspored}
                        </td>
                        <td className="border border-gray-600">{el.type}</td>
                        <td className="border border-gray-600">
                          {el.povrsina}
                        </td>
                        <td className="border border-gray-600">{el.pod}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center lg:w-[80%] w-full">
              <h1 className="text-2xl w-full p-4 text-white bg-teal-600 mb-4">
                DOSTUPNOST STANOVA
              </h1>
              <table className="border border-gray-600 w-full">
                <tbody>
                  <tr className="border border-gray-600">
                    <td className="border border-gray-600">BROJ STANA</td>
                    <td className="border border-gray-600">SPRAT</td>
                    <td className="border border-gray-600">PRODAT</td>
                  </tr>
                  {fullStan.dostupnost.map((el, i) => {
                    return (
                      <tr
                        key={i}
                        className={
                          el.prodat === "DA"
                            ? "border border-gray-600 bg-gray-500"
                            : "border border-gray-600"
                        }>
                        <td className={"border border-gray-600"}>
                          {el.brojStana}
                        </td>
                        <td className="border border-gray-600">{el.sprat}</td>
                        <td className="border border-gray-600">{el.prodat}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StanPage;
