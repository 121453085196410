import React from "react";
import sourceVideo from "../../assets/Download.mp4";
const Benefits = () => {
  return (
    <div
      className="bkg flex flex-col items-center py-14 justify-center"
      id="zastoMi">
      <p className="text-3xl w-4/5 md:w-7/12 text-gray-900 text-center">
        Ako tražite stan koji će ispuniti sve vaše kriterijume, onda ste na
        pravom mestu. Mi nudimo:
      </p>
      <ul className="lg:w-2/3">
        <li className="text-xl m-8 text-gray-900 ">
          <span className="text-teal-600 font-bold">
            Kvalitetnu gradnju i opremljenost:
          </span>{" "}
          Naši stanovi su izgrađeni po najvišim standardima, sa kvalitetnim
          materijalima i modernom opremom.
        </li>
        <li className="text-xl m-8 text-gray-900 ">
          <span className="text-teal-600 font-bold">
            Odličnu lokaciju i infrastrukturu:
          </span>{" "}
          Naši stanovi se nalaze na atraktivnim lokacijama u gradu.
        </li>
        <li className="text-xl m-8 text-gray-900 ">
          <span className="text-teal-600 font-bold">Pristupačne cene:</span>{" "}
          Stremimo da ponudimo konkurentne cene kako bismo omogućili našim
          kupcima da ostvare svoje snove o vlastitom domu bez prevelikog
          finansijskog opterećenja.
        </li>
      </ul>
      <video controls src={sourceVideo}></video>
    </div>
  );
};

export default Benefits;
