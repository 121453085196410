import React from "react";
import UlaziButtons from "./UlaziButtons";

const Lamela = () => {
  return (
    <div className="bkg flex flex-col items-center relative">
      <div className="md:w-7/12 w-3/4 flex justify-center items-center">
        <span className="md:w-40 h-px bg-gray-400"></span>
        <h1 className="px-4 py-8 text-2xl font-thin text-gray-500 text-center">
          ODABERETE ŽELJENI ULAZ
        </h1>
        <span className="md:w-40 h-px bg-gray-400"></span>
      </div>
      <p className="text-xl w-4/5 md:w-7/12 text-gray-900 text-center">
        Imamo preko 120 stanova u ponudi, na savršenoj lokaciji na magistrali u
        ulici Save Kovalčevića ( preko puta Sadović Petrola). U ponudi imamo
        stanove raznih veličina (od 35 do 100m<sup>2</sup>) izrađeni po
        najnovijim standardima.
      </p>
      <h3 className="text-2xl w-4/5 font-thin text-center py-4 text-teal-900">
        Svi stanovi imaju građevinsku dozvolu, lift i garažu.
      </h3>
      <UlaziButtons />
    </div>
  );
};

export default Lamela;
