import React from "react";
import { Carousel, Typography } from "@material-tailwind/react";
import Layout from "../Layout/layout";
import Headline from "../Sections/Headline";
import Lamela from "../Sections/Lamela";
import Benefits from "../Sections/Benefits";
import Iskustvo from "../Sections/Iskustvo";
import front from "../../assets/buildingPhotos/zgrada1.jpeg";
import left from "../../assets/buildingPhotos/zgrada2.jpeg";
import right from "../../assets/buildingPhotos/zgrada3.jpeg";

const HomePage = () => {
  return (
    <Layout>
      <div className="relative z-10">
        <Carousel
          className="w-full h-screen bg-teal-900"
          autoplay={true}
          autoplayDelay={5000}
          loop={true}>
          <div className="relative h-full w-full">
            <img src={front} alt="1" className="h-screen w-full object-cover" />
            <div className="absolute left-2/4 translate-x-[-50%] translate-y-full  inset-0 grid h-1/3 w-min md:w-1/3 place-items-center bg-teal-900/75">
              <div className="w-full text-center p-4">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl text-center">
                  DOBRODOŠLI U ZGRADU BAJROVIĆ
                </Typography>
              </div>
            </div>
          </div>
          <div className="relative h-full w-full">
            <img src={left} alt="2" className="h-full w-full object-cover" />
            <div className="absolute left-2/4 translate-x-[-50%] translate-y-full  inset-0 grid h-1/3  w-min md:w-1/3 lg:w-[40%] place-items-center bg-teal-900/75">
              <div className="w-full text-center p-4">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl  text-center">
                  NAJVEĆA I NAJMODERNIJA ZGRADA NA SAVRŠENOJ LOKACIJI
                </Typography>
              </div>
            </div>
          </div>
          <div className="relative h-full w-full">
            <img src={right} alt="3" className="h-full w-full object-cover" />
            <div className="absolute left-2/4 translate-x-[-50%] translate-y-full  inset-0 grid h-1/3 w-2/3 md:w-1/3 place-items-center bg-teal-900/75">
              <div className="w-full text-center p-4">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl text-center">
                  UPOZNAJTE NAS BLIŽE
                </Typography>
                <a
                  href="#zastoMi"
                  className="py-4 px-8  w-full  border-0 bg-teal-700 text-white text-md  font-body font-bold  transition-all hover:bg-teal-400">
                  ZAŠTO KOD NAS
                </a>
              </div>
            </div>
          </div>
        </Carousel>
        <Headline />
        <Lamela />
        <Iskustvo />
        <Benefits />
      </div>
    </Layout>
  );
};

export default HomePage;
