import React, { useState, useEffect } from "react";
import { IoArrowUp } from "react-icons/io5";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";
import Footer from "./Footer";

const Layout = (props) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div>
      {isVisible ? (
        <div className="fixed z-30 bottom-10 right-10">
          <a href="#nav">
            <IoArrowUp className="text-5xl p-2 bg-black/30 text-teal-400 transition-all hover:bg-black/50 hover:text-white" />
          </a>
        </div>
      ) : null}
      {screenSize.width > 860 ? <Header /> : <HeaderMobile />}
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
