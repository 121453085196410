import React, { useContext } from "react";
import photo from "../../assets/logoTest.png";
import { IoIosArrowDropright } from "react-icons/io";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import { StanContext } from "../context";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { setStanType, setUlaz } = useContext(StanContext);
  const navigate = useNavigate();

  return (
    <footer className="sticky bottom-[-50%] lg:bottom-0 left-0 w-full z-0 bg-teal-900 pt-16">
      <div className="w-full flex md:flex-row justify-evenly text-white pb-16 flex-col xl:px-10 px-4">
        <div className="md:w-1/4 w-full">
          <div>
            <h3 className="font-thin text-xl">Navigacija sajta</h3>
          </div>
          <ul className="pt-4 list-none w-full">
            <li className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <Link
                to="/"
                className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Naslovna</p>
              </Link>
            </li>
            <li className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <Link
                to="/about"
                className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>O nama</p>
              </Link>
            </li>
            <li className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <Link
                to="/apartments"
                className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Stanovi u ponudi</p>
              </Link>
            </li>
            <li className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <Link
                to="/enters"
                className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Ulazi</p>
              </Link>
            </li>
            <li className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <Link
                to="/contact"
                className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Kontakt</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="md:w-1/4 w-full">
          <div>
            <h3 className="font-thin text-xl">Strukture stanova</h3>
          </div>

          <ul className="pt-4 list-none">
            <li
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all"
              onClick={() => {
                setStanType("jednoiposobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}>
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Jednoiposobni</p>
              </div>
            </li>
            <li
              onClick={() => {
                setStanType("dvosobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Dvosobni</p>
              </div>
            </li>
            <li
              onClick={() => {
                setStanType("dvoiposobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Dvoiposobni</p>
              </div>
            </li>
            <li
              onClick={() => {
                setStanType("trosobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Trosobni</p>
              </div>
            </li>
            <li
              onClick={() => {
                setStanType("četorosobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Četvorosobni</p>
              </div>
            </li>
            <li
              onClick={() => {
                setStanType("petosobni stanovi");
                setUlaz(false);
                navigate("/apartments-listing");
              }}
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all">
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Petosobni</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="md:w-1/4 w-full">
          <div>
            <h3 className="font-thin text-xl">Ulazi</h3>
          </div>

          <ul className="pt-4 list-none">
            <li
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all"
              onClick={() => {
                setUlaz("A");
                navigate("/enters/select-apartment");
              }}>
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Ulaz A</p>
              </div>
            </li>
            <li
              className="cursor-pointer pb-2 w-full border-b border-solid border-gray-400 lg:hover:border-teal-600 transition-all"
              onClick={() => {
                setUlaz("B");
                navigate("/enters/select-apartment");
              }}>
              <div className="cursor-pointer flex items-center gap-4 text-white no-underline text-lg lg:hover:text-teal-600">
                <IoIosArrowDropright />
                <p>Ulaz B</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="px-7 py-5 bg-teal-600 flex flex-col lg:flex-row items-center justify-between text-white lg:gap-20">
        <div className="flex flex-col items-center gap-4">
          <img src={photo} alt="slika" className="h-16" />
          <p className="w-max">2023 BAJROVIĆ GROUP d.o.o.</p>
          <p className="w-max"><a href="tel:+381644011748"><span>&#9742;</span> +381 64 4011748</a></p>
        </div>
        <div className="flex justify-center gap-8 mt-4 lg:mr-20">
          <BsInstagram className="text-3xl transition-all lg:hover:text-teal-900" />
          <BsFacebook className="text-3xl transition-all lg:hover:text-teal-900" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
