import React from "react";
import Layout from "../Layout/layout";
import photo from "../../assets/logoTest.png";

const AboutPage = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center relative z-10 bkg">
        <div className="w-[80%] flex flex-col items-center py-12  mt-20 md:mt-0 ">
          <div className="w-full flex justify-center items-center gap-8 text-center">
            <span className="w-[30%] border-b border-solid border-b-gray-700 hidden md:block"></span>
            <h2 className="md:text-4xl text-3xl mt-8 lg:mt-0 font-thin text-teal-900">
              Građevinska firma
            </h2>
            <span className="w-[30%] border-b border-solid border-b-gray-700 hidden md:block"></span>
          </div>
          <h1 className="m-5 md:text-5xl text-4xl text-center text-teal-600">
            BAJROVIĆ GROUP D.O.O.
          </h1>
        </div>
        <div className="md:w-[80%] w-[90%] pt-4 pb-32 flex flex-col md:flex-row  justify-center items-center">
          <div className="md:w-[66%] w-full md:pr-4">
            <p className="text-justify text-xl mx-2">
              <b>Bajrović Group 2023</b> je ugledna firma sa sedištem u Novom
              Pazaru, posvećena pružanju vrhunskih rešenja u sektoru nekretnina.
              Sa dugogodišnjim iskustvom i stručnim timom, naša firma se ističe
              kao lider u oblasti razvoja i prodaje stambenih jedinica na
              atraktivnim lokacijama. Fokusirani smo na stvaranje domova koji
              kombinuju funkcionalnost, udobnost i stil, prilagođavajući se
              potrebama modernog života.
            </p>
            <p className="text-justify text-xl mx-2 text-teal-600">
              Naša filozofija poslovanja temelji se na kvalitetu,
              transparentnosti i zadovoljstvu klijenata. Svaki projekat koji
              preduzimamo sprovodi se sa pažnjom prema detaljima, osiguravajući
              visoke standarde gradnje i materijala. Bajrović Group 2023 se
              ponosi svojom reputacijom pouzdanog partnera koji pruža sigurnost
              i mir uma kupcima tokom čitavog procesa kupovine stana.
            </p>
            <p className="text-justify text-xl mx-2">
              Pored stvaranja visokokvalitetnih stambenih prostora, Bajrović
              Group 2023 takođe se angažuje u raznim inicijativama koje
              doprinose lokalnoj zajednici i društvenom razvoju. Svesni smo
              važnosti održivog razvoja i društvene odgovornosti, te aktivno
              radimo na ostvarivanju pozitivnih promena u okolini u kojoj
              poslujemo. Bajrović Group 2023 predstavlja partnera na koga se
              možete osloniti u ostvarivanju svojih životnih i poslovnih ciljeva
              u oblasti nekretnina.
            </p>
          </div>
        </div>
      </div>
      <div className=" w-full bg-teal-900 pt-20 pb-8 flex md:flex-row flex-col justify-center items-center relative z-10">
        <img
          src={photo}
          alt="logo"
          className="max-h-36 absolute top-14 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50"
        />
        <div className="md:w-1/2 w-[95%] pt-8">
          <h1 className="text-teal-400 ml-4 mb-4 text-3xl text-center md:text-start">
            Kvalitetno | Sigurno | Povoljno
          </h1>
          <p className="text-xl  text-gray-200 text-justify leading-2 py-2 px-4">
            Sa dubokim korenima u lokalnoj zajednici, Bajrović Group 2023 je
            ponosan što doprinosi ekonomskom rastu i razvoju Novog Pazara. Naš
            tim stručnjaka posvećen je pružanju vrhunskog korisničkog iskustva,
            prateći kupce od početka do kraja procesa kupovine, obezbeđujući im
            sigurnost i pouzdanost.
          </p>
          <p className="text-xl  text-teal-400 text-justify leading-2 py-2 px-4">
            Trenutno smo okrenuti izgradnji objekata u Naselju Čerkez Mahala,
            Ul. Save Kovačevića, koja je jedna od urbanih lokacija u zoni grada
            koja je u razvitku kako za život, tako i za poslovanje.
          </p>
          <p className="text-xl  text-gray-200 text-justify leading-2 py-2 px-4">
            Pozivom dobjate sve potrebne informacije,a obučeni smo tako da vam
            na najbolji način približmo prostor koji prodajemo.
          </p>
        </div>
        <div className="md:w-[30%] w-full flex justify-center">
          <div className="w-80 border border-aliceblue py-4 px-0 flex flex-col items-center text-gray-50 text-xl">
            <p>Podaci o firmi:</p>
            <p className="text-teal-400">BAJROVIĆ GROUP D.O.O.</p>
            <p>Generala Zivkovica 23,</p>
            <p>36300 Novi Pazar</p>
            <p>Matični br.: 21876097</p>
            <p>Poreski br.: 113488034</p>
            <p>
              <a
                href="https://pretraga2.apr.gov.rs/EnterpriseWebSearchWeb/Details/Details?beid=13126369&rnd=F952AFFDD9C71E86C5BE50C938FBEC8C03FDE738"
                className="text-teal-400 no-underline">
                Link ka APRu
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="py-20 text-center relative z-10 bkg">
        <h1 className=" text-3xl font-thin">
          Do danas, <span className="text-teal-600"> mnogi ljudi</span> već su
          obezbedili svoje domove koje ćemo mi izgraditi.
        </h1>
      </div>
    </Layout>
  );
};

export default AboutPage;
