import React from "react";
// import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import photo from "../../assets/logoTest.png";
// import { StanContext } from "../context";

const Header = () => {
  // const [arrow, setArrow] = useState(false);
  // const [arrow2, setArrow2] = useState(false);
  // const { setStanType, setUlaz } = useContext(StanContext);
  const navigate = useNavigate();

  return (
    <nav
      className=" w-full flex justify-between items-center xl:px-10 px-4 py-1 bg-teal-900 relative z-20"
      id="nav">
      <img
        src={photo}
        alt="slika"
        className="h-20 my-2"
        onClick={() => {
          navigate("/");
        }}
      />
      <ul className="w-2/3 list-none  flex justify-end items-center  text-white text-lg font-mono">
        <Link to="/">
          <li className="cursor-pointer xl:px-10 px-4 py-1 lg:hover:bg-teal-800 transition-all">
            POČETNA
          </li>
        </Link>
        <Link to="/about">
          <li className="cursor-pointer border-s-2 border-e-2 xl:px-10 px-4 py-1 lg:hover:bg-teal-800 transition-all">
            O NAMA
          </li>
        </Link>
        <li
          className="cursor-pointer border-r-2 xl:px-10 px-4 py-1 lg:hover:bg-teal-800 transition-all "
          // onClick={(e) => {
          // setArrow(!arrow);
          // setArrow2(false);
          // }}
          onClick={() => {
            navigate("/apartments");
          }}>
          <div className="flex items-center active:bg-teal-90  transition-all">
            <p>STANOVI U PONUDI</p>
            {/* {!arrow ? (
              <MdKeyboardArrowDown className="text-xl" />
            ) : (
              <MdKeyboardArrowUp className="text-xl" />
            )} */}
          </div>

          {/* {arrow ? (
            <div className="bg-teal-900 absolute mt-5 p-2 z-50">
              <ul>
                <li> </li>
                <li
                  className="cursor-pointer py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setStanType("garsonjere i jednosobni stanovi");
                    setUlaz(false);
                    navigate("/apartments-listing");
                  }}>
                  Garsonjere i Jednosobni Stanovi
                </li>
                <li
                  className="cursor-pointer py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setStanType("jednoiposobni stanovi");
                    setUlaz(false);
                    navigate("/apartments-listing");
                  }}>
                  Jednosobni Stanovi
                </li>
                <li
                  className="cursor-pointer py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setStanType("dvosobni stanovi");
                    setUlaz(false);
                    navigate("/apartments-listing");
                  }}>
                  Dvosobni Stanovi
                </li>
                <li
                  className="cursor-pointer py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setStanType("dvoiposobni stanovi");
                    setUlaz(false);
                    navigate("/apartments-listing");
                  }}>
                  Dvoiposobni Stanovi
                </li>
                <li
                  className="cursor-pointer py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setStanType("trosobni stanovi");
                    setUlaz(false);
                    navigate("/apartments-listing");
                  }}>
                  Trosobni Stanovi
                </li>
              </ul>
            </div>
          ) : null} */}
        </li>
        {/* <li
          className="cursor-pointer xl:px-10 px-4 py-1 border-s-2 border-e-2 lg:hover:bg-teal-800 transition-all"
          onClick={() => {
            setArrow2(!arrow2);
            setArrow(false);
          }}
          onDoubleClick={() => {
            navigate("/enters");
          }}>
          <div className=" flex items-center active:bg-teal-800 transition-all">
            <p>ULAZI</p>
            {!arrow2 ? (
              <MdKeyboardArrowDown className="text-xl" />
            ) : (
              <MdKeyboardArrowUp className="text-xl" />
            )}
          </div>
          {arrow2 && (
            <div className="bg-teal-900 absolute mt-5 p-2 z-50">
              <ul>
                <li
                  className="cursor-pointer w-40 py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setUlaz("A");
                    navigate("/enters/select-apartment");
                  }}>
                  ULAZ A{" "}
                </li>
                <li
                  className="cursor-pointer w-40 py-1 px-3 lg:hover:bg-teal-800 transition-all"
                  onClick={() => {
                    setUlaz("B");
                    navigate("/enters/select-apartment");
                  }}>
                  ULAZ B{" "}
                </li>
              </ul>
            </div>
          )}
        </li> */}
        <Link to="/contact">
          <li className="cursor-pointer xl:px-10 px-4 py-1 lg:hover:bg-teal-800 transition-all">
            KONTAKT
          </li>
        </Link>
      </ul>
    </nav>
  );
};

export default Header;
