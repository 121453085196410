import React from "react";
import photo from "../../assets/logoTest.png";

const Iskustvo = () => {
  return (
    <div className="w-full py-8 bg-teal-900 flex flex-col gap-4 items-center">
      <img src={photo} alt="slika" className="h-20" />
      <p className="text-xl text-white font-body w-4/5 text-center">
        Naša firma nudi vrhunski <span className="text-teal-600">kvalitet</span>{" "}
        stanova na <span className="text-teal-600">povoljnim</span> lokacijama.
      </p>
    </div>
  );
};

export default Iskustvo;
