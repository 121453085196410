import JEDNOSOBNI_A from "./RENDERI LAMELA A/LAMELA A2.jpg";
import JEDNOSOBNI_A_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 2 A.png";
import JEDNOSOBNI_A_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A2.png";
import DVOSOBNI_B from "./LAMELA B RENDERI/B 8.jpg";
import DVOSOBNI_B_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S8 B.png";
import DVOSOBNI_B_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B8.png";
import DVOIPOSOBNI_B from "./LAMELA B RENDERI/B 1.jpg";
import DVOIPOSOBNI_B_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S1 B.png";
import DVOIPOSOBNI_B_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B1.png";
import DVOIPOSOBNI_B2 from "./LAMELA B RENDERI/B 4.jpg";
import DVOIPOSOBNI_B2_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S4 B.png";
import DVOIPOSOBNI_B2_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B4.png";
import DVOIPOSOBNI_B3 from "./LAMELA B RENDERI/B 5.jpg";
import DVOIPOSOBNI_B3_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S5 B.png";
import DVOIPOSOBNI_B3_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B5.png";
import DVOIPOSOBNI_B4 from "./LAMELA B RENDERI/B 6.jpg";
import DVOIPOSOBNI_B4_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S6 B.png";
import DVOIPOSOBNI_B4_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B6.png";
import DVOIPOSOBNI_B5 from "./LAMELA B RENDERI/B 7.jpg";
import DVOIPOSOBNI_B5_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S7 B.png";
import DVOIPOSOBNI_B5_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B7.png";
import TROSOBNI_B from "./LAMELA B RENDERI/B 9.jpg";
import TROSOBNI_B_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S9 B.png";
import TROSOBNI_B_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B9.png";
import CETVOROSOBNI_B from "./LAMELA B RENDERI/B 3.jpg";
import CETVOROSOBNI_B_OSNOVA from "./OSNOVE STANOVA/LAMELA B SLIKE/S3 B.png";
import CETVOROSOBNI_B_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/B3.png";
import TROSOBNI_A from "./RENDERI LAMELA A/LAMELA A1.jpg";
import TROSOBNI_A_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 1 A.png";
import TROSOBNI_A_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A1.png";
import TROSOBNI_A2 from "./RENDERI LAMELA A/LAMELA A5.jpg";
import TROSOBNI_A2_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 5 A.png";
import TROSOBNI_A2_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A5.png";
import TROSOBNI_A3 from "./RENDERI LAMELA A/LAMELA A11.jpg";
import TROSOBNI_A3_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 11 A.png";
import TROSOBNI_A3_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A11.png";
import TROSOBNI_A4 from "./RENDERI LAMELA A/LAMELA A12.jpg";
import TROSOBNI_A4_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 12 A.png";
import TROSOBNI_A4_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A12.png";
import CETVOROSOBNI_A from "./RENDERI LAMELA A/LAMELA A4.jpg";
import CETVOROSOBNI_A_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 4 A.png";
import CETVOROSOBNI_A_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A4.png";
import PETOSOBNI_A from "./RENDERI LAMELA A/LAMELA A3.jpg";
import PETOSOBNI_A_OSNOVA from "./OSNOVE STANOVA/LAMELA A SLIKE/S 3 A.png";
import PETOSOBNI_A_POZICIJA from "./OSNOVE ZA POZICIJU STANOVA/A3.png";

const stanoviData = {
  lamelaA: {
    "jednoiposobni stanovi": [
      {
        id: 1,
        ulaz: "A",
        name: "STANOVI BR. (2, 7, 14, 21, 28, 35, 42)",
        površinaStana: "35.04",
        imgUrl: JEDNOSOBNI_A,
        triDPrikaz: JEDNOSOBNI_A,
        orijentacijaStana: JEDNOSOBNI_A_POZICIJA,
        rasporedProstorija: JEDNOSOBNI_A_OSNOVA,
        dostupnost: [
          { sprat: 1, brojStana: 2, prodat: "NE" },
          { sprat: 2, brojStana: 7, prodat: "NE" },
          { sprat: 3, brojStana: 14, prodat: "NE" },
          { sprat: 4, brojStana: 21, prodat: "NE" },
          { sprat: 5, brojStana: 28, prodat: "DA" },
          { sprat: 6, brojStana: 35, prodat: "DA" },
          { sprat: 7, brojStana: 42, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 3.53,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 3.95,
            pod: "Ker. pločice",
          },
          {
            type: "DNEVNI BORAVAK+ NIŠA",
            raspored: 3,
            povrsina: 18.53,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 4,
            povrsina: 7.45,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 5,
            povrsina: 2.66,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "dvosobni stanovi": [],
    "dvoiposobni stanovi": [],
    "trosobni stanovi": [
      {
        id: 1,
        ulaz: "A",
        name: "STANOVI BR.(1,6,13,20,27,34,41)",
        površinaStana: "59.08",
        imgUrl: TROSOBNI_A,
        triDPrikaz: TROSOBNI_A,
        orijentacijaStana: TROSOBNI_A_POZICIJA,
        rasporedProstorija: TROSOBNI_A_OSNOVA,
        dostupnost: [
          { sprat: 1, brojStana: 1, prodat: "NE" },
          { sprat: 2, brojStana: 6, prodat: "NE" },
          { sprat: 3, brojStana: 13, prodat: "NE" },
          { sprat: 4, brojStana: 20, prodat: "NE" },
          { sprat: 5, brojStana: 27, prodat: "DA" },
          { sprat: 6, brojStana: 34, prodat: "NE" },
          { sprat: 7, brojStana: 41, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 7.07,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.89,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 3,
            povrsina: 12.79,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 4,
            povrsina: 11.9,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 5,
            povrsina: 19.72,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 6,
            povrsina: 4.54,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 2,
        ulaz: "A",
        name: "STANOVI BR.(5, 10, 17, 24, 31, 38, 45)",
        površinaStana: "72.73",
        imgUrl: TROSOBNI_A2,
        triDPrikaz: TROSOBNI_A2,
        orijentacijaStana: TROSOBNI_A2_POZICIJA,
        rasporedProstorija: TROSOBNI_A2_OSNOVA,
        dostupnost: [
          { sprat: 1, brojStana: 5, prodat: "NE" },
          { sprat: 2, brojStana: 10, prodat: "NE" },
          { sprat: 3, brojStana: 17, prodat: "NE" },
          { sprat: 4, brojStana: 24, prodat: "DA" },
          { sprat: 5, brojStana: 31, prodat: "NE" },
          { sprat: 6, brojStana: 38, prodat: "NE" },
          { sprat: 7, brojStana: 45, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 7.68,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.28,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 2.63,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 13.63,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 10.6,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 22.0,
            pod: "Parket",
          },
          {
            type: "OSTAVA",
            raspored: 7,
            povrsina: 2.8,
            pod: "Ker. pločice",
          },
          {
            type: "LOĐA",
            raspored: 8,
            povrsina: 10.63,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 3,
        ulaz: "A",
        name: "STANOVI BR. (11, 18, 25, 32, 39, 46)",
        površinaStana: "65.91",
        imgUrl: TROSOBNI_A3,
        triDPrikaz: TROSOBNI_A3,
        orijentacijaStana: TROSOBNI_A3_POZICIJA,
        rasporedProstorija: TROSOBNI_A3_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 11, prodat: "NE" },
          { sprat: 3, brojStana: 18, prodat: "DA" },
          { sprat: 4, brojStana: 25, prodat: "DA" },
          { sprat: 5, brojStana: 32, prodat: "DA" },
          { sprat: 6, brojStana: 39, prodat: "NE" },
          { sprat: 7, brojStana: 46, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 10.99,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.6,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 1.88,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 15.02,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 10.04,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 22.56,
            pod: "Parket",
          },
          {
            type: "TERASA",
            raspored: 7,
            povrsina: 2.86,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 4,
        ulaz: "A",
        name: "STANOVI BR. (12, 19, 26, 33, 40, 47)",
        površinaStana: "63.76",
        imgUrl: TROSOBNI_A4,
        triDPrikaz: TROSOBNI_A4,
        orijentacijaStana: TROSOBNI_A4_POZICIJA,
        rasporedProstorija: TROSOBNI_A4_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 12, prodat: "NE" },
          { sprat: 3, brojStana: 19, prodat: "NE" },
          { sprat: 4, brojStana: 26, prodat: "DA" },
          { sprat: 5, brojStana: 33, prodat: "DA" },
          { sprat: 6, brojStana: 40, prodat: "DA" },
          { sprat: 7, brojStana: 47, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 8.04,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.36,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 2.14,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 11.8,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 10.02,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 23.15,
            pod: "Parket",
          },
          {
            type: "TERASA",
            raspored: 7,
            povrsina: 5.22,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "četvorosobni stanovi": [
      {
        id: 1,
        ulaz: "A",
        name: "STANOVI BR. (4, 9, 16, 23, 30, 37, 44)",
        površinaStana: "72.11",
        imgUrl: CETVOROSOBNI_A,
        triDPrikaz: CETVOROSOBNI_A,
        orijentacijaStana: CETVOROSOBNI_A_POZICIJA,
        rasporedProstorija: CETVOROSOBNI_A_OSNOVA,
        dostupnost: [
          { sprat: 1, brojStana: 4, prodat: "NE" },
          { sprat: 2, brojStana: 9, prodat: "NE" },
          { sprat: 3, brojStana: 16, prodat: "NE" },
          { sprat: 4, brojStana: 23, prodat: "DA" },
          { sprat: 5, brojStana: 30, prodat: "DA" },
          { sprat: 6, brojStana: 37, prodat: "DA" },
          { sprat: 7, brojStana: 44, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 9.69,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.79,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 1.7,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 13.03,
            pod: " Parket",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 5,
            povrsina: 9.55,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 6,
            povrsina: 10.1,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 7,
            povrsina: 20.42,
            pod: "Parket",
          },
          {
            type: "TERASA",
            raspored: 8,
            povrsina: 5.06,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "petosobni stanovi": [
      {
        id: 1,
        ulaz: "A",
        name: "STANOVI BR. (3, 8, 15, 22, 29, 36, 43)",
        površinaStana: "99.49",
        imgUrl: PETOSOBNI_A,
        triDPrikaz: PETOSOBNI_A,
        orijentacijaStana: PETOSOBNI_A_POZICIJA,
        rasporedProstorija: PETOSOBNI_A_OSNOVA,
        dostupnost: [
          { sprat: 1, brojStana: 3, prodat: "NE" },
          { sprat: 2, brojStana: 8, prodat: "NE" },
          { sprat: 3, brojStana: 15, prodat: "NE" },
          { sprat: 4, brojStana: 22, prodat: "NE" },
          { sprat: 5, brojStana: 29, prodat: "NE" },
          { sprat: 6, brojStana: 36, prodat: "NE" },
          { sprat: 7, brojStana: 43, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 14.56,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.1,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 1.74,
            pod: "Ker. pločice",
          },
          {
            type: "OSTAVA",
            raspored: 4,
            povrsina: 1.58,
            pod: "Ker. pločice",
          },
          {
            type: "SOBA 1",
            raspored: 5,
            povrsina: 8.75,
            pod: " Parket",
          },
          {
            type: "SOBA 2",
            raspored: 6,
            povrsina: 8.69,
            pod: " Parket",
          },
          {
            type: "SOBA 3",
            raspored: 7,
            povrsina: 11.73,
            pod: "Parket",
          },
          {
            type: "SP. SOBA 1",
            raspored: 8,
            povrsina: 13.5,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 9,
            povrsina: 24.12,
            pod: "Parket",
          },
          {
            type: "TERASA 1",
            raspored: 10,
            povrsina: 6.15,
            pod: "Ker. pločice",
          },
          {
            type: "TERASA 2",
            raspored: 11,
            povrsina: 6.65,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
  },
  lamelaB: {
    "jednoiposobni stanovi": [],
    "dvosobni stanovi": [
      {
        id: 1,
        ulaz: "B",
        name: "STANOVI BR. (8, 20, 32, 44, 56, 68)",
        imgUrl: DVOSOBNI_B,
        površinaStana: "41.34",
        triDPrikaz: DVOSOBNI_B,
        orijentacijaStana: DVOSOBNI_B_POZICIJA,
        rasporedProstorija: DVOSOBNI_B_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 8, prodat: "NE" },
          { sprat: 3, brojStana: 20, prodat: "DA" },
          { sprat: 4, brojStana: 32, prodat: "NE" },
          { sprat: 5, brojStana: 44, prodat: "DA" },
          { sprat: 6, brojStana: 56, prodat: "DA" },
          { sprat: 5, brojStana: 68, prodat: "DA" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 2.31,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.42,
            pod: "Ker. pločice",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 3,
            povrsina: 10.0,
            pod: " Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 4,
            povrsina: 22.83,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 5,
            povrsina: 3.06,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "dvoiposobni stanovi": [
      {
        id: 1,
        ulaz: "B",
        name: "STANOVI BR.(1, 2, 11, 12, 13, 14, 23, 24, 25, 26, 35, 36, 37, 38, 47, 48, 49, 50, 59, 60, 61, 62, 71, 72)",
        površinaStana: "63.88",
        imgUrl: DVOIPOSOBNI_B,
        triDPrikaz: DVOIPOSOBNI_B,
        orijentacijaStana: DVOIPOSOBNI_B_POZICIJA,
        rasporedProstorija: DVOIPOSOBNI_B_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 1, prodat: "NE" },
          { sprat: 2, brojStana: 2, prodat: "NE" },
          { sprat: 2, brojStana: 11, prodat: "NE" },
          { sprat: 2, brojStana: 12, prodat: "NE" },
          { sprat: 3, brojStana: 13, prodat: "NE" },
          { sprat: 3, brojStana: 14, prodat: "NE" },
          { sprat: 3, brojStana: 23, prodat: "DA" },
          { sprat: 3, brojStana: 24, prodat: "DA" },
          { sprat: 4, brojStana: 25, prodat: "NE" },
          { sprat: 4, brojStana: 26, prodat: "NE" },
          { sprat: 4, brojStana: 35, prodat: "NE" },
          { sprat: 4, brojStana: 36, prodat: "NE" },
          { sprat: 5, brojStana: 37, prodat: "DA" },
          { sprat: 5, brojStana: 38, prodat: "DA" },
          { sprat: 5, brojStana: 47, prodat: "DA" },
          { sprat: 5, brojStana: 48, prodat: "DA" },
          { sprat: 6, brojStana: 49, prodat: "DA" },
          { sprat: 6, brojStana: 50, prodat: "DA" },
          { sprat: 6, brojStana: 59, prodat: "DA" },
          { sprat: 6, brojStana: 60, prodat: "DA" },
          { sprat: 7, brojStana: 61, prodat: "NE" },
          { sprat: 7, brojStana: 62, prodat: "DA" },
          { sprat: 7, brojStana: 71, prodat: "NE" },
          { sprat: 7, brojStana: 72, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 6.86,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.61,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 3,
            povrsina: 14.53,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 4,
            povrsina: 10.92,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 5,
            povrsina: 24.1,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 6,
            povrsina: 3.84,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 2,
        ulaz: "B",
        name: "STANOVI BR.(4, 16, 28, 40, 52, 64)",
        površinaStana: "53.18",
        imgUrl: DVOIPOSOBNI_B2,
        triDPrikaz: DVOIPOSOBNI_B2,
        orijentacijaStana: DVOIPOSOBNI_B2_POZICIJA,
        rasporedProstorija: DVOIPOSOBNI_B2_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 4, prodat: "DA" },
          { sprat: 3, brojStana: 16, prodat: "NE" },
          { sprat: 4, brojStana: 28, prodat: "NE" },
          { sprat: 5, brojStana: 40, prodat: "NE" },
          { sprat: 6, brojStana: 52, prodat: "NE" },
          { sprat: 7, brojStana: 64, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 5.56,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.77,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 3,
            povrsina: 12.92,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 4,
            povrsina: 7.38,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 5,
            povrsina: 19.8,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 6,
            povrsina: 4.39,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 3,
        ulaz: "B",
        name: "STANOVI BR.(5, 17, 29, 41, 53, 65)",
        površinaStana: "56.71",
        imgUrl: DVOIPOSOBNI_B3,
        triDPrikaz: DVOIPOSOBNI_B3,
        orijentacijaStana: DVOIPOSOBNI_B3_POZICIJA,
        rasporedProstorija: DVOIPOSOBNI_B3_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 5, prodat: "NE" },
          { sprat: 3, brojStana: 17, prodat: "NE" },
          { sprat: 4, brojStana: 29, prodat: "DA" },
          { sprat: 5, brojStana: 41, prodat: "DA" },
          { sprat: 6, brojStana: 53, prodat: "NE" },
          { sprat: 7, brojStana: 65, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 5.83,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.77,
            pod: "Ker. pločice",
          },
          {
            type: "OSTAVA",
            raspored: 3,
            povrsina: 2.34,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 13.49,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 7.17,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 20.62,
            pod: "Parket",
          },
          {
            type: "TERASA",
            raspored: 7,
            povrsina: 4.24,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 4,
        ulaz: "B",
        name: "STANOVI BR.(6, 18, 30, 42, 54, 66)",
        površinaStana: "58.44",
        imgUrl: DVOIPOSOBNI_B4,
        triDPrikaz: DVOIPOSOBNI_B4,
        orijentacijaStana: DVOIPOSOBNI_B4_POZICIJA,
        rasporedProstorija: DVOIPOSOBNI_B4_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 6, prodat: "DA" },
          { sprat: 3, brojStana: 18, prodat: "DA" },
          { sprat: 4, brojStana: 30, prodat: "DA" },
          { sprat: 5, brojStana: 42, prodat: "DA" },
          { sprat: 6, brojStana: 54, prodat: "NE" },
          { sprat: 7, brojStana: 66, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 7.07,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.61,
            pod: "Ker. pločice",
          },
          {
            type: "OSTAVA",
            raspored: 3,
            povrsina: 2.34,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 11.67,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 8.45,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 20.62,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 7,
            povrsina: 4.49,
            pod: "Ker. pločice",
          },
        ],
      },
      {
        id: 5,
        ulaz: "B",
        name: "STANOVI BR.(7, 19, 31, 43, 55, 67)",
        površinaStana: "58.44",
        imgUrl: DVOIPOSOBNI_B5,
        triDPrikaz: DVOIPOSOBNI_B5,
        orijentacijaStana: DVOIPOSOBNI_B5_POZICIJA,
        rasporedProstorija: DVOIPOSOBNI_B5_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 6, prodat: "DA" },
          { sprat: 3, brojStana: 18, prodat: "DA" },
          { sprat: 4, brojStana: 30, prodat: "DA" },
          { sprat: 5, brojStana: 42, prodat: "DA" },
          { sprat: 6, brojStana: 54, prodat: "NE" },
          { sprat: 7, brojStana: 66, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 7.07,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 5.61,
            pod: "Ker. pločice",
          },
          {
            type: "OSTAVA",
            raspored: 3,
            povrsina: 2.34,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 11.67,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 8.45,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 20.62,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 7,
            povrsina: 4.49,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "trosobni stanovi": [
      {
        id: 1,
        ulaz: "B",
        name: "STANOVI BR.(9, 10, 21, 22, 33, 34, 45, 45, 57, 58, 69, 70)",
        površinaStana: "57.86",
        imgUrl: TROSOBNI_B,
        triDPrikaz: TROSOBNI_B,
        orijentacijaStana: TROSOBNI_B_POZICIJA,
        rasporedProstorija: TROSOBNI_B_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 9, prodat: "NE" },
          { sprat: 2, brojStana: 10, prodat: "NE" },
          { sprat: 3, brojStana: 21, prodat: "DA" },
          { sprat: 3, brojStana: 22, prodat: "DA" },
          { sprat: 4, brojStana: 33, prodat: "DA" },
          { sprat: 4, brojStana: 34, prodat: "NE" },
          { sprat: 5, brojStana: 45, prodat: "DA" },
          { sprat: 5, brojStana: 46, prodat: "NE" },
          { sprat: 6, brojStana: 57, prodat: "NE" },
          { sprat: 6, brojStana: 58, prodat: "NE" },
          { sprat: 7, brojStana: 69, prodat: "NE" },
          { sprat: 7, brojStana: 70, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 7.65,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.96,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 2.0,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 4,
            povrsina: 11.79,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 5,
            povrsina: 10.23,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 6,
            povrsina: 20.32,
            pod: "Parket",
          },
          {
            type: "TERASA",
            raspored: 7,
            povrsina: 2.7,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
    "četvorosobni stanovi": [
      {
        id: 1,
        ulaz: "B",
        name: "STANOVI BR.(3, 15, 27, 39, 51, 63)",
        površinaStana: "83.67",
        imgUrl: CETVOROSOBNI_B,
        triDPrikaz: CETVOROSOBNI_B,
        orijentacijaStana: CETVOROSOBNI_B_POZICIJA,
        rasporedProstorija: CETVOROSOBNI_B_OSNOVA,
        dostupnost: [
          { sprat: 2, brojStana: 3, prodat: "NE" },
          { sprat: 3, brojStana: 15, prodat: "NE" },
          { sprat: 4, brojStana: 27, prodat: "NE" },
          { sprat: 5, brojStana: 39, prodat: "DA" },
          { sprat: 6, brojStana: 51, prodat: "DA" },
          { sprat: 7, brojStana: 63, prodat: "NE" },
        ],
        kvadraturaProstorija: [
          {
            type: "HODNIK",
            raspored: 1,
            povrsina: 11.49,
            pod: " Parket",
          },
          {
            type: "KUPATILO",
            raspored: 2,
            povrsina: 4.03,
            pod: "Ker. pločice",
          },
          {
            type: "WC",
            raspored: 3,
            povrsina: 3.04,
            pod: "Ker. pločice",
          },
          {
            type: "OSTAVA",
            raspored: 4,
            povrsina: 2.14,
            pod: "Ker. pločice",
          },
          {
            type: "SPAVAĆA SOBA",
            raspored: 5,
            povrsina: 14.69,
            pod: " Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 6,
            povrsina: 14.58,
            pod: "Parket",
          },
          {
            type: "DEČIJA SOBA",
            raspored: 7,
            povrsina: 10.74,
            pod: "Parket",
          },
          {
            type: "DNEVNI BORAVAK + NIŠA",
            raspored: 8,
            povrsina: 18.27,
            pod: "Parket",
          },
          {
            type: "LOĐA",
            raspored: 9,
            povrsina: 3.7,
            pod: "Ker. pločice",
          },
          {
            type: "LOĐA",
            raspored: 10,
            povrsina: 3.58,
            pod: "Ker. pločice",
          },
        ],
      },
    ],
  },
};
export default stanoviData;
